<template>
    <HomeCard :style="styles" arrow-background-color="#FFFFFF" class="!border-none" @click="handleClick">
        <div class="flex flex-col justify-between gap-6">
            <span class="text-white font-semibold bg-white/25 w-fit rounded-lg p-2">{{ estimatedTime }} min</span>
            <span class="md:text-2xl font-semibold tracking-tight text-white">{{ title }}</span>
        </div>
    </HomeCard>
</template>

<script setup>
import { useMeshGradient } from "~vue/composables/useMeshGradient";
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { defineProps } from "vue";

import HomeCard from "./HomeCard.vue";

const props = defineProps({
    isTrending: Boolean,
    isSaved: Boolean,
    title: {
        type: String,
        default: "",
    },
    url: {
        type: String,
        default: "",
    },
    estimatedTime: {
        type: Number,
        default: 5,
    },
});

function handleClick() {
    logUserInteraction("suggested_topics_clicked", { title: props.title });
    if (props.url) {
        openUrl(props.url);
    }
}

const { styles } = useMeshGradient({
    backgroundColor: "#b3adc1",
    meshColors: ["#ac6f95", "#6aa4bd", "#b05883", "#E8E8E8"],
});
</script>
