<template>
    <HomeSectionContainer v-if="actionItems.length > 0">
        <template #title>
            <div class="grow flex items-center justify-between md:justify-start gap-6">
                <div>
                    <p class="text-base font-semibold tracking-tight text-[#8C8C8C]">This week</p>
                    <h3 class="text-2xl font-semibold tracking-tight">Following up on</h3>
                </div>
                <span class="h-10 w-10">
                    <ProgressCircle :progress="progress.percent" :content="progress.content" />
                </span>
            </div>
        </template>
        <div class="flex flex-col gap-2">
            <div v-for="(item, index) in actionItems" :key="index" class="flex justify-between items-center gap-4 py-6 px-3 md:px-6 bg-white border-2 border-[#E8E8E8] rounded-xl">
                <BaseCheckbox :checked="item.checked" @toggle-check="toggleActionItem(item)">
                    {{ item.content }}
                </BaseCheckbox>
                <BaseButton v-if="!!item.chat_url" theme="secondary" class="text-[#555BA2] font-semibold flex gap-3 items-center whitespace-nowrap" @click="openChat(item)">
                    Start Chat<span class="rounded-full p-4 w-5 h-5 bg-[#555ba2]/25 grid place-content-center"><i class="bi bi-arrow-right text-lg icon-bold"></i></span>
                </BaseButton>
            </div>
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import { useFetch } from "~vue/api";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseCheckbox from "~vue/components/form/BaseCheckbox.vue";
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { openUrl } from "~vue/utils";
import { logError } from "~vue/utils/logUtils";
import { computed } from "vue";

import HomeSectionContainer from "./HomeSectionContainer.vue";

const { actionItemsData, defaultChatUrl } = defineProps({
    actionItemsData: {
        type: Object,
        required: true,
    },
    defaultChatUrl: {
        type: String,
        required: true,
    },
});

const actionItems = computed(() => actionItemsData.items);

const progress = computed(() => {
    const completed = actionItems.value.filter((item) => item.checked).length;

    return {
        percent: (completed / actionItems.value.length) * 100,
        content: `${completed}/${actionItems.value.length}`,
    };
});

const toggleActionItem = async (actionItem) => {
    const payload = JSON.stringify({ id: actionItemsData.id, action_item: { ...actionItem, checked: !actionItem.checked } });
    const { onFetchError } = await useFetch("/accounts/action_items").post(payload).json();
    onFetchError(() => {
        logError("Failed to update action item");
    });

    router.reload({ only: ["action_items"] });
};

const openChat = async (item) => {
    if (!item.checked) {
        await toggleActionItem(item);
    }
    nextTick(() => {
        openUrl(item.chat_url ?? defaultChatUrl);
    });
};
</script>
