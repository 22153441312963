<template>
    <HomeCard :style="meshStyles" class="!min-w-[300px] !max-w-[320px] !border-none" :show-arrow="showArrow" :arrow-background-color="arrowBackgroundColor" @click="handleClick">
        <div class="flex flex-col justify-between gap-6" :class="[useMeshBackground ? 'text-white' : 'text-[#8C8C8C]', { grow: !content }]">
            <div class="flex flex-col gap-2">
                <slot name="header">
                    <i class="bi bi-lightning-charge-fill text-2xl"></i>
                </slot>
                <span class="md:text-2xl font-semibold tracking-tighter">{{ title }}</span>
            </div>
            <slot name="content">
                <span v-if="!!content" class="font-medium">{{ formattedContent }}</span>
            </slot>
        </div>
    </HomeCard>
</template>

<script setup>
import { useMeshGradient } from "~vue/composables/useMeshGradient";
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed } from "vue";

import HomeCard from "./HomeCard.vue";

const MAX_CONTENT_CHAR_LENGTH = 150;

const { title, url, content, useMeshBackground } = defineProps({
    title: { type: String, default: "" },
    url: { type: String, default: "" },
    content: { type: String, default: "" },
    showArrow: { type: Boolean, default: true },
    useMeshBackground: { type: Boolean, default: false },
});

const formattedContent = computed(() => {
    if (content.length < MAX_CONTENT_CHAR_LENGTH) {
        return content;
    }
    const contentSplitByWords = content.split(" ");

    return (
        contentSplitByWords.reduce((acc, word) => {
            if (acc.length + word.length > MAX_CONTENT_CHAR_LENGTH) {
                return acc;
            }
            return `${acc} ${word}`;
        }, "") + "..."
    );
});

const handleClick = () => {
    logUserInteraction("saved_insights_clicked", { title: title });
    if (url) {
        openUrl(url);
    }
};

const { styles } = useMeshGradient({
    backgroundColor: "#487a92",
    meshColors: ["#aa8a72", "#a9aaa0", "#766d7f", "#426aa9"],
});

const meshStyles = computed(() => (useMeshBackground ? styles : undefined));
const arrowBackgroundColor = computed(() => (useMeshBackground ? "#FFFFFF" : undefined));
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
